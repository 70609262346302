import LandingPage from '@/modules/landing/LandingPage'
import PrivacyPolicy from '@/modules/privacy/PrivacyPolicy'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'

export const routerManager = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
    </>,
  ),
)
