import i18n from 'i18next'

// hermes doesn't support PluralRules
// https://github.com/facebook/hermes/blob/main/doc/IntlAPIs.md#not-yet-supported
import 'intl-pluralrules'
import { initReactI18next } from 'react-i18next'
import en from '@/core/translation/en.json'

const resources = {
  en: {
    translation: en,
  },
}

i18n.use(initReactI18next).init({
  returnNull: false,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  parseMissingKeyHandler: (key: string, defaultValue?: string) => {
    if (defaultValue !== undefined) {
      return defaultValue
    }
    throw new Error(`${key} translation not found`)
  },
  resources,
})

export default i18n
