import { ReactElement, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { DefaultTheme } from 'styled-components'

export const Theme: DefaultTheme = {
  fonts: {
    light: 'GilroyLight',
    normal: 'Gilroy',
    bold: 'GilroyBold',
  },
  sizes: {
    size0: 3,
    size1: 6,
    size2: 12,
    size3: 18,
    size4: 24,
    size5: 30,
  },
  colors: {
    main: '#549E39',
    secondary: '#1C78D8',
    black: '#000',
    hint: '#8B8B8B',
    gray1: '#F6F5F5',
  },
  helper: {
    isSmallDevice: false,
  },
}

const ThemeManager = ({
  children,
}: {
  children: ReactElement | ReactElement[]
}) => {
  const [theme, setTheme] = useState<DefaultTheme>(Theme)

  const updateIsSmallDevice = () => {
    setTheme({
      ...theme,
      helper: { ...theme.helper, isSmallDevice: window.innerWidth <= 481 },
    })
  }

  const removeResizeListener = () => {
    window.removeEventListener('resize', updateIsSmallDevice)
  }

  useEffect(() => {
    window.addEventListener('resize', updateIsSmallDevice)
    updateIsSmallDevice()
    return removeResizeListener
  }, [])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeManager
