import { Trans } from 'react-i18next'
import { HintText, TitleText } from '@/core/components/texts'
import {
  Container,
  Image,
  Section,
} from '@/modules/landing/components/try-app/TryApp.styles'
import trialPeriodImage from '@/assets/images/landing-sections/trial-period.png'
import RegularButton from '@/core/components/buttons/regular-button/RegularButton'
import i18n from '@/core/translation'
import { useAppContext } from '@/core/contexts/AppContext'

export const TryApp = () => {
  const { setIsJoinWaitListModalOpened } = useAppContext()

  const joinWaitList = () => {
    setIsJoinWaitListModalOpened(true)
  }

  return (
    <Container>
      <Section>
        <TitleText>
          <Trans i18nKey={'tryApp.title'} />
        </TitleText>
        <HintText style={{ color: 'white' }}>
          <Trans i18nKey={'tryApp.subTitle'} />
        </HintText>
        <RegularButton
          onClick={joinWaitList}
          type="secondary"
          title={i18n.t('actions.joinWaitList')}
        ></RegularButton>
      </Section>
      <Section style={{ textAlign: 'center' }}>
        <Image src={trialPeriodImage} alt="logo" />
      </Section>
    </Container>
  )
}

export default TryApp
