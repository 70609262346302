import styled from 'styled-components'

export const Input = styled.input`
  padding: ${({ theme }) => theme.sizes.size3}px;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: 20px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  width: 90%;

  transition:
    box-shadow 0.3s,
    border-color 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  &:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`
export const InputContainer = styled.div`
  width: 100%;
`
