import toast, { Toast } from 'react-hot-toast'
import { Theme } from '@/core/theme/ThemeManager'

export const displaySuccessMessage = (message: string, options?: Toast) => {
  toast.success(message, {
    ...options,
    duration: 5000,
    position: 'top-center',
    iconTheme: options?.iconTheme || {
      primary: Theme.colors.main,
      secondary: '#fff',
    },
  })
}
