import { Trans } from 'react-i18next'
import {
  HintText,
  MediumTitleText,
  SecondarySpanText,
  TitleText,
} from '@/core/components/texts'
import {
  Container,
  Section,
  SectionContainer,
} from '@/modules/landing/components/tools-goal/ToolsGoal.styles'
import appleImage from '@/assets/images/goal-tools/apple.svg'
import mindImage from '@/assets/images/goal-tools/mind.svg'
import moveImage from '@/assets/images/goal-tools/bicycle.svg'
import i18n from '@/core/translation'

export const ToolsGoal = () => {
  return (
    <Container>
      <TitleText style={{ textAlign: 'center' }}>
        <Trans
          i18nKey={'toolsGoalSection.title'}
          components={{
            secondary: <SecondarySpanText />,
          }}
        />
      </TitleText>
      <HintText style={{ textAlign: 'center' }}>
        <Trans i18nKey={'toolsGoalSection.subTitle'} />
      </HintText>
      <SectionContainer>
        <Section>
          <img src={appleImage} alt="Apple" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section1Title')}
          </MediumTitleText>
          <HintText>{i18n.t('toolsGoalSection.section1SubTitle')}</HintText>
        </Section>
        <Section>
          <img src={moveImage} alt="Move" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section2Title')}
          </MediumTitleText>
          <HintText>{i18n.t('toolsGoalSection.section2SubTitle')}</HintText>
        </Section>
        <Section>
          <img src={mindImage} alt="Move" />
          <MediumTitleText>
            {i18n.t('toolsGoalSection.section3Title')}
          </MediumTitleText>
          <HintText>{i18n.t('toolsGoalSection.section3SubTitle')}</HintText>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default ToolsGoal
