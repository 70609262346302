import i18n from '@/core/translation'
import {
  AppName,
  HeaderContainer,
} from '@/core/components/layout/navigation-header/NavigationHeader.styles'
import logo from '@/assets/images/logo.svg'

export const NavigationHeader = () => {
  return (
    <HeaderContainer>
      <img src={logo} alt="Logo" />
      <AppName>{i18n.t('appName')}</AppName>
    </HeaderContainer>
  )
}

export default NavigationHeader
