import React, { ChangeEvent } from 'react'

import {
  Input,
  InputContainer,
} from '@/core/components/inputs/regular-input/RegularInput.styles'

interface Props {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

function RegularInput({
  onChange,
  ...props
}: React.HTMLProps<HTMLInputElement> & Props) {
  return (
    <InputContainer>
      <Input onChange={onChange} {...props} />
    </InputContainer>
  )
}

export default RegularInput
