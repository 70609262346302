import { Trans } from 'react-i18next'
import { NavigationHeader } from '@/core/components/layout'
import {
  BigTitleText,
  HintText,
  SecondarySpanText,
} from '@/core/components/texts'
import {
  Container,
  DownloadsContainer,
  Image,
  Section,
} from '@/modules/landing/components/main-section/MainSection.styles'
import bannerImage from '@/assets/images/smooth2.jpeg'
// import googlePlayImage from '@/assets/images/downloads/google-play.svg'
import appStoreImage from '@/assets/images/downloads/app-store.svg'

export const MainSection = () => {
  return (
    <Container>
      <Section>
        <NavigationHeader />
        <BigTitleText>
          <Trans
            i18nKey={'mainSection.title'}
            components={{
              secondary: <SecondarySpanText />,
            }}
          />
        </BigTitleText>
        <HintText>
          <Trans i18nKey={'mainSection.subTitle'} />
        </HintText>
        <DownloadsContainer>
          <a href="https://apps.apple.com/us/app/gapway/id1669341436">
            <img src={appStoreImage} alt="App Store" />
          </a>
          {/* <a href="#">
            <img src={googlePlayImage} alt="Google Play" />
          </a> */}
        </DownloadsContainer>
      </Section>
      <Section>
        <Image src={bannerImage} alt="Banner" />
      </Section>
    </Container>
  )
}

export default MainSection
