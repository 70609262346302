import { Trans } from 'react-i18next'
import { HintText, PrimarySpanText, TitleText } from '@/core/components/texts'
import {
  Container,
  Image,
  Section,
  SectionContainer,
} from '@/modules/landing/components/healthy-journey/HealthyJourney.styles'
import healthyJourney1 from '@/assets/images/landing-sections/healthy-journey1.png'
import healthyJourney2 from '@/assets/images/landing-sections/healthy-journey2.png'

export const HealthyJourney = () => {
  return (
    <Container>
      <SectionContainer>
        <Section style={{ alignSelf: 'center' }}>
          <TitleText>
            <Trans
              i18nKey={'healthyJourney.title'}
              components={{
                secondary: <PrimarySpanText />,
              }}
            />
          </TitleText>
          <HintText>
            <Trans i18nKey={'healthyJourney.subTitle'} />
          </HintText>
        </Section>
        <Section style={{ textAlign: 'center' }}>
          <Image src={healthyJourney1} alt="logo" />
        </Section>
      </SectionContainer>

      <SectionContainer style={{ marginTop: '2%' }}>
        <Section style={{ textAlign: 'center' }}>
          <Image src={healthyJourney2} alt="logo" />
        </Section>
        <Section style={{ alignSelf: 'center' }}>
          <HintText>
            <Trans i18nKey={'healthyJourney.subTitle2'} />
          </HintText>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default HealthyJourney
