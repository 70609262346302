import React from 'react'
import {
  Button,
  ButtonText,
} from '@/core/components/buttons/regular-button/RegularButton.styles'
import { LoadingSpinner } from '@/core/components/spinner'

interface Props {
  title: string
  type?: 'main' | 'secondary'
  loading?: boolean
}

function RegularButton({
  title,
  type = 'main',
  loading = false,
  ...props
}: React.HTMLProps<HTMLButtonElement> & Props) {
  return (
    <Button type={type} {...props} disabled={loading}>
      <ButtonText>{loading ? <LoadingSpinner /> : title}</ButtonText>
    </Button>
  )
}

export default RegularButton
