import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
`

export const AppName = styled.h3`
  color: ${({ theme }) => theme.colors.main};
  font-family: ${({ theme }) => theme.fonts.bold};
`
