import React from 'react'
import ThemeManager from '@/core/theme/ThemeManager'
import Modal from 'react-modal'
import { AppContextProvider } from '@/core/contexts/AppContext'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { routerManager } from '@/core/routes/RouteManager'
Modal.setAppElement('#root')

function App() {
  return (
    <ThemeManager>
      <AppContextProvider>
        <RouterProvider router={routerManager} />
        <Toaster />
      </AppContextProvider>
    </ThemeManager>
  )
}

export default App
