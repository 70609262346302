import styled from 'styled-components'

export const BigTitleText = styled.h1`
  font-size: 50px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 2px;
`

export const TitleText = styled.h1`
  font-size: 40px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 2px;
`

export const MediumTitleText = styled.h2`
  font-size: 20px;
  font-family: ${({ theme }) => theme.fonts.bold};
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 2px;
`

export const HintText = styled.h3`
  font-family: ${({ theme }) => theme.fonts.light};
  color: ${({ theme }) => theme.colors.hint};
  line-height: 1.5rem;
`

export const SecondarySpanText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`

export const PrimarySpanText = styled.span`
  color: ${({ theme }) => theme.colors.main};
`
