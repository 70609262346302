import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyB4To5mDw6CJxlrcQ61KZibAdHMA5nOY0w',
  authDomain: 'gapway-e36da.firebaseapp.com',
  projectId: 'gapway-e36da',
  storageBucket: 'gapway-e36da.appspot.com',
  messagingSenderId: '421540655873',
  appId: '1:421540655873:web:5fe757cf046ea4501ba100',
  measurementId: 'G-H4YBZX71CX',
}

const app = initializeApp(firebaseConfig)
const DB = getFirestore(app)

export { DB }
