import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 10%;
  margin-left: 8%;
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: ${({ theme }) => theme.sizes.size5}px;
`

export const Section = styled.div`
  flex: 1;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  ${({ theme }) =>
    theme.helper.isSmallDevice &&
    `
    border-radius: 50px;
  `}
`

export const DownloadsContainer = styled.div`
  margin-top: 5%;
  display: flex;
  gap: ${({ theme }) => theme.sizes.size1}px;
  justify-content: flex-start;
  ${({ theme }) =>
    theme.helper.isSmallDevice &&
    `
    align-items: center;
    flex-direction: column;
`}
`
