import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 7%;
  margin-left: 10%;
  margin-right: 10%;
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: ${({ theme }) => theme.sizes.size5}px;
`

export const Section = styled.div`
  flex: 1;
`

export const Image = styled.img`
  width: 100%;
  align-self: center;
`
