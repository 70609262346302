import styled from 'styled-components'

export const Button = styled.button<{ type: string }>`
  padding: 1%;
  padding-left: 15%;
  padding-right: 15%;
  background-color: ${({ theme, type }) =>
    type === 'main' ? theme.colors.main : theme.colors.secondary};
  border-radius: 15px;
  border: 2px solid transparent; /* Set border to transparent by default */
  cursor: pointer;
  transition:
    box-shadow 0.3s,
    border-color 0.3s;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
`

export const ButtonText = styled.p`
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.bold};
  letter-spacing: 1px;
  color: white;
`
