import { Trans } from 'react-i18next'
import { HintText, PrimarySpanText, TitleText } from '@/core/components/texts'
import {
  ActionsContainer,
  Container,
  Image,
  Link,
  Section,
} from '@/modules/landing/components/mobile-app/MobileApp.styles'
import mobileSectionImage from '@/assets/images/landing-sections/mobile.png'
import rightArrowImage from '@/assets/icons/right-arrow.svg'
import RegularButton from '@/core/components/buttons/regular-button/RegularButton'
import i18n from '@/core/translation'
import { useAppContext } from '@/core/contexts/AppContext'

export const MobileApp = () => {
  const { setIsJoinWaitListModalOpened } = useAppContext()

  return (
    <Container>
      <Section style={{ textAlign: 'center' }}>
        <Image src={mobileSectionImage} alt="logo" />
      </Section>
      <Section style={{ marginTop: '5%' }}>
        <TitleText>
          <Trans
            i18nKey={'mobileSection.title'}
            components={{
              secondary: <PrimarySpanText />,
            }}
          />
        </TitleText>
        <HintText>
          <Trans i18nKey={'mobileSection.subTitle'} />
        </HintText>
        <ActionsContainer>
          <RegularButton
            onClick={() => {
              setIsJoinWaitListModalOpened(true)
            }}
            title={i18n.t('actions.joinWaitList')}
          />
          <a
            href="#"
            style={{
              display: 'flex',
              flexDirection: 'row',
              textDecoration: 'none',
            }}
          >
            <Link>
              {i18n.t('actions.howItWorks')}
              <img
                style={{ paddingLeft: 10, alignSelf: 'center' }}
                src={rightArrowImage}
                alt="right-arrow"
              />
            </Link>
          </a>
        </ActionsContainer>
      </Section>
    </Container>
  )
}

export default MobileApp
