import React, { createContext, useContext, useState } from 'react'

const AppContext = createContext<{
  isJoinWaitListModalOpened: boolean
  setIsJoinWaitListModalOpened: React.Dispatch<React.SetStateAction<boolean>>
}>({
  isJoinWaitListModalOpened: false,
  setIsJoinWaitListModalOpened: () => {},
})

export function useAppContext() {
  return useContext(AppContext)
}

export function AppContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [isJoinWaitListModalOpened, setIsJoinWaitListModalOpened] =
    useState(false)

  return (
    <AppContext.Provider
      value={{
        isJoinWaitListModalOpened,
        setIsJoinWaitListModalOpened,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
