import { DB } from '@/core/stores/firebase'
import { DocumentReference, addDoc, collection } from 'firebase/firestore'

export default class Database<CreateType> {
  private collectionName = ''

  constructor(collectionName: string) {
    this.collectionName = collectionName
  }

  async create(data: CreateType): Promise<DocumentReference | null> {
    try {
      const docRef = await addDoc(
        collection(DB, this.collectionName),
        data as { [key: string]: any },
      )
      return docRef
    } catch (e) {
      console.error('failed to create firestore data: ', {
        error: e,
        name: this.collectionName,
        data,
      })
      return null
    }
  }
}
