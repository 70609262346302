import { StyledSpinner } from '@/core/components/spinner/loading-spinner/LoadingSpinner.styles'

function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <StyledSpinner />
    </div>
  )
}

export default LoadingSpinner
