import { waitListStore } from '@/core/stores'
import Modal from 'react-modal'
import closeIcon from '@/assets/icons/close.svg'
import { CloseIcon } from '@/modules/landing/components/modals/join-wait-list/JoinWaitListModal.styles'
import { useAppContext } from '@/core/contexts/AppContext'
import RegularButton from '@/core/components/buttons/regular-button/RegularButton'
import i18n from '@/core/translation'
import { RegularInput } from '@/core/components/inputs'
import { ChangeEvent, FormEvent, useState } from 'react'
import { displaySuccessMessage } from '@/core/utils/toast'

export const JoinWaitListModal = () => {
  const [email, setEmail] = useState('')
  const [loading, setIsLoading] = useState(false)
  const { isJoinWaitListModalOpened, setIsJoinWaitListModalOpened } =
    useAppContext()

  const joinWaitList = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email.trim()) {
      return
    }
    setIsLoading(true)
    const ref = await waitListStore.create({ email: email })
    if (ref?.id) {
      displaySuccessMessage(i18n.t('waitList.addedSuccess'))
      setEmail('')
      setIsJoinWaitListModalOpened(false)
    }
    setIsLoading(false)
  }

  function closeModal() {
    setIsJoinWaitListModalOpened(false)
  }

  return (
    <div>
      <Modal
        isOpen={isJoinWaitListModalOpened}
        style={{
          content: {
            minWidth: '50%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 25,
            opacity: 0.93,
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.6)',
          },
        }}
        shouldCloseOnOverlayClick
        onRequestClose={closeModal}
        contentLabel="Join Wait List Modal"
      >
        <CloseIcon onClick={closeModal} src={closeIcon} alt="right-arrow" />
        <form
          style={{ marginTop: '10%', textAlign: 'center' }}
          onSubmit={joinWaitList}
        >
          <div style={{ marginBottom: 10, width: '100%' }}>
            <RegularInput
              required
              type="email"
              placeholder="example@example.com"
              value={email}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value)
              }}
            />
          </div>
          <RegularButton
            loading={loading}
            target="submit"
            type="secondary"
            title={i18n.t('actions.joinWaitList')}
          />
        </form>
      </Modal>
    </div>
  )
}

export default JoinWaitListModal
