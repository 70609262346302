import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 7%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 3%;
  padding-bottom: 3%;
  background-color: ${({ theme }) => theme.colors.gray1};
`

export const SectionContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.helper.isSmallDevice ? 'column' : 'row'};
  gap: 50px;
`

export const Section = styled.div`
  flex: 1;
  text-align: center;
`
